import {Container, Sprite, Graphics, Texture, Text} from 'pixi.js';
import {SCREEN_WIDTH, SCREEN_HEIGHT, TOUCH_DEVICE, CAN_SHARE} from 'Const';
import { sceneManager } from 'SceneManager';
import { Tween24, Ease24 } from 'tween24';
import { sound } from '@pixi/sound';

const TIME_LIMIT = 10000;

export class Picture extends Container {
  constructor(text){
    super();
    this.text = text + '\n';
    this.background = new Graphics();
    this.background.beginFill(0x000000);
    this.background.drawRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);
    this.background.endFill();
    this.addChild(this.background);

    this.background.interactive = true;

    let appCanvas = sceneManager.app.view;
    let appCanvasCtx = appCanvas.getContext('webgl');
    if(!appCanvasCtx){
      appCanvasCtx = appCanvas.getContext('webgl2');
    }
    const appViewPixels = new Uint8ClampedArray(appCanvasCtx.drawingBufferWidth * appCanvasCtx.drawingBufferHeight * 4);
    appCanvasCtx.readPixels(
      0, 
      0, 
      appCanvasCtx.drawingBufferWidth, 
      appCanvasCtx.drawingBufferHeight, 
      appCanvasCtx.RGBA, 
      appCanvasCtx.UNSIGNED_BYTE, 
      appViewPixels
    );
    this.pictureCanvas = document.createElement('canvas');
    this.pictureCanvas.width = appCanvasCtx.drawingBufferWidth;
    this.pictureCanvas.height = appCanvasCtx.drawingBufferHeight;
    const pictureCanvasCtx = this.pictureCanvas.getContext('2d');
    const pictureCanvasData = pictureCanvasCtx.createImageData(appCanvasCtx.drawingBufferWidth, appCanvasCtx.drawingBufferHeight);
    const pictureCanvasDataArray = pictureCanvasData.data;
    for(let i = 0; i < appViewPixels.length; i++){
      pictureCanvasDataArray[i] = appViewPixels[i];
    }
    pictureCanvasCtx.putImageData(pictureCanvasData, 0, 0);
    pictureCanvasCtx.translate(0, pictureCanvasData.height);
    pictureCanvasCtx.scale(1, -1);
    pictureCanvasCtx.drawImage(this.pictureCanvas, 0, 0);
    const computedImageData = pictureCanvasCtx.getImageData(0, 0, this.pictureCanvas.width, this.pictureCanvas.height);

    const pictureSprite = new Sprite(Texture.fromBuffer(computedImageData.data, computedImageData.width, computedImageData.height));
    pictureSprite.anchor.set(0.5);
    pictureSprite.position.x = pictureSprite.width * 0.5;
    pictureSprite.position.y = pictureSprite.height * 0.5;
    this.addChild(pictureSprite);
    sound.play('se_shutter');
    Tween24.tween(pictureSprite.scale, 1, Ease24._6_ExpoInOut, {x:0.7, y:0.7}).play();

    const shareText = new Text(TOUCH_DEVICE && CAN_SHARE ? 'Share?' : 'Download?', {
      fontFamily: 'Rubik Moonrocks',
      fontSize: 100,
      fill: 'white',
    });
    this.addChild(shareText);
    shareText.anchor.set(0.5);
    shareText.position.x = SCREEN_WIDTH * 0.5;
    shareText.position.y = SCREEN_HEIGHT * 0.92;
    shareText.scale.set(0);

    this.timerText = new Text('10', {
      fontFamily: 'Rubik Moonrocks',
      fontSize: 100,
      fill: 'white',
    });
    this.addChild(this.timerText);
    this.timerText.anchor.set(0.5);
    this.timerText.position.x = SCREEN_WIDTH * 0.9;
    this.timerText.position.y = SCREEN_HEIGHT * 0.92;
    this.timerText.scale.set(0);
    this.isClose = false;
    this.onShareStartBind = this.onShareStart.bind(this);
    Tween24.serial(
      Tween24.wait(1),
      Tween24.parallel(
        Tween24.tween(shareText.scale, 1, Ease24._ElasticOut, {x:1, y:1}),
        Tween24.serial(
          Tween24.wait(0.2),
          Tween24.tween(this.timerText.scale, 1, Ease24._ElasticOut, {x:1, y:1}),
        ),
        Tween24.func(() => {
          this.isCountdown = true;
          this.timer = 0;
          this.latestTime = performance.now();
          this.background.on('pointerup', this.onShareStartBind);
          sceneManager.addBodyPointerUpListener(this.onShareStartBind);
        }),
      ),
    ).play();
    this.pivot.set(SCREEN_WIDTH * 0.5, SCREEN_HEIGHT * 0.5);
    this.position.x = SCREEN_WIDTH * 0.5;
    this.position.y = SCREEN_HEIGHT * 0.5;
  }

  onShareStart(){
    if(this.isClose)return;
    this.isCountdown = false;
    if(TOUCH_DEVICE && CAN_SHARE){
      this.onShare();
    }else{
      this.onDownload();
    }
  }

  UpdateScene(dt){
    const now = performance.now();
    if(this.isCountdown){
      const elapsedTime = now - this.latestTime;
      this.timer += elapsedTime;
      const closeLimit = Math.ceil((TIME_LIMIT - this.timer) * 0.001);
      this.timerText.text = closeLimit;
      if(closeLimit <= 0){
        this.onClose();
      }
    }
    this.latestTime = now;
  }

  onClose(){
    this.isClose = true;
    this.isCountdown = false;
    sceneManager.removeBodyPointerUpListener(this.onShareStartBind);
    Tween24.serial(
      Tween24.parallel(
        Tween24.tween(this.scale, 0.5, Ease24._3_CubicOut, {x:2, y:2}),
        Tween24.tween(this, 0.5, Ease24._3_CubicOut, {alpha:0}),
      ),
      Tween24.func(() => {
        this.emit('close');
        this.destroy({children:true})
      }),
    ).play();
  }

  async onShare(){
    const dataURL = this.pictureCanvas.toDataURL('image/png');
    const toBlob = (base64) => {
      const decodedData = atob(base64.replace(/^.*,/, ""));
      const buffers = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        buffers[i] = decodedData.charCodeAt(i);
      }
      try {
        const blob = new Blob([buffers.buffer], {
          type: 'image.png',
        });
        return blob;
      } catch (e) {
        return null;
      }
    };
    const blob = toBlob(dataURL);
    const imageFile = new File([blob], 'image.png', {
      type: 'image/png',
    });
    await navigator.share({
        text: this.text + '#VeryLongAnimals\n#VeryLongRun\n',
        url: location.href,
        files: [imageFile],
    }).then(() => {
      this.onClose();
    }).catch((error) => {
      this.isCountdown = true;
      this.latestTime = performance.now();
    });
  }

  async onDownload(){
    const a = document.createElement('a');
    document.body.append(a);
    a.download = 'VeryLongRun_' + Date.now();
    a.href = this.pictureCanvas.toDataURL('image/png');
    a.click();
    a.remove();
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.onClose();
  }
}
