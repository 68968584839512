import * as Web3 from 'web3';
import {INFURA_URL, CONTRACT} from 'Const.js';

const web3 = new Web3(INFURA_URL);
const abi = [
  {
    "constant": true,
    "inputs": [
      {
        "name": "_tokenId",
        "type": "uint256"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "name": "",
        "type": "string"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function",
  },
];

export async function GetTokenImageOriginalUrl(tokenId){
  try{
    const contract = new web3.eth.Contract(abi, CONTRACT);
    const tokenUri = await contract.methods.tokenURI(tokenId).call();
    return await fetch(tokenUri)
    .then(data => data.json())
    .then(json => {
      var imgUrl = "";
      if(Object.keys(json).includes("imageUrl")){
        imgUrl = json.imageUrl;
      }
      else if(Object.keys(json).includes("image")){
        imgUrl = json.image;
      }
      return imgUrl;
    });
  }catch{
    return '';
  }
}