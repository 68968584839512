import {Container, utils, Sprite} from 'pixi.js';
import {SCREEN_WIDTH, SCREEN_HEIGHT, IMAGE_STAGE_BUSH, SCROLL_SPEED} from 'Const';

export class RunSceneFrontLayer extends Container{
  Init(){
    this.initialized = true;
    this.frontBushes = [];
    for(let i = 0; i < 3; i++){
      const bush = new Sprite(utils.TextureCache[IMAGE_STAGE_BUSH]);
      bush.scale.set(2);
      bush.y = SCREEN_HEIGHT - bush.height + 12;
      bush.x = SCREEN_WIDTH * i;
      this.addChild(bush);
      this.frontBushes.push(bush);
    }
  }

  UpdateScene(dt){
    if(this.initialized){
      for(let i = 0; i < this.frontBushes.length; i++){
        this.frontBushes[i].x -= dt * SCROLL_SPEED;
        if(this.frontBushes[i].x <= -SCREEN_WIDTH){
          this.frontBushes[i].x += SCREEN_WIDTH * this.frontBushes.length;
        }
      }
    }
  }
}
