import {Container, Loader, Graphics} from 'pixi.js';
import {Spine} from 'pixi-spine';
import {SCREEN_WIDTH, SCREEN_HEIGHT, SCROLL_SPEED} from 'Const';

const INTERVAL_MAX = SCREEN_WIDTH * 0.5;
const INTERVAL_MIN = SCREEN_WIDTH * 0.5;

export class Coin extends Container {
  constructor(){
    super();
    this.coinSpine = new Spine(Loader.shared.resources.spineCoin.spineData);
    this.addChild(this.coinSpine);
    this.collider = new Graphics();
    this.collider.beginFill(0x00cef5);
    this.collider.drawRect(0, 0, 32, 32);
    this.collider.endFill();
    this.collider.position.x = -this.collider.width * 0.5;
    this.collider.position.y = -this.collider.height * 0.5;
    this.addChild(this.collider);
    this.collider.renderable = false;
    this.coinSpine.state.setAnimation(0, 'normal', true);
    this.renderable = false;
    this.isGetted = false;
  }

  Reset(lastCoin){
    if(lastCoin){
      this.position.x = lastCoin.position.x + INTERVAL_MIN + INTERVAL_MAX * Math.random();
    }else{
      this.position.x = SCREEN_WIDTH + INTERVAL_MIN + INTERVAL_MAX * Math.random();
    }
    if(0.6 <= Math.random()){
      this.position.y = SCREEN_HEIGHT * 0.45;
    }else{
      this.position.y = SCREEN_HEIGHT * 0.65;
    }
    this.isGetted = false;
    this.coinSpine.state.setAnimation(0, 'normal', true);
    this.renderable = true;
  }

  Getted(){
    this.isGetted = true;
    this.coinSpine.state.setAnimation(0, 'getted', false);
  }

  UpdateScene(dt){
    this.position.x -= dt * SCROLL_SPEED;
  }
}
