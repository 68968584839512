import { Animal } from 'objects/Animal';
import {SCREEN_WIDTH, SCREEN_HEIGHT, SCROLL_SPEED} from 'Const';

const ANIMATION_SCALE_BASE = 1;

const INTERVAL_MAX = SCREEN_WIDTH * 2;
const INTERVAL_MIN = SCREEN_WIDTH * 1.5;

export class ObstructiveAnimal extends Animal {
  constructor(spineData, inirializedAnimalTextureParser){
    super(spineData, inirializedAnimalTextureParser);
    this.ChangeAnimationScale(1.1);
    this.collider.position.y = -this.collider.height * 0.9;
    if(inirializedAnimalTextureParser){
      // 座り状態でbodycolorにしてしまうと視認性が悪いから一律boundaryColorCodeで上書き
      this.spine.skeleton.findSlot('leg_front').color.setFromString(inirializedAnimalTextureParser.boundaryColorCode.replace('0x', '#'));
      this.spine.skeleton.findSlot('leg_back').color.setFromString(inirializedAnimalTextureParser.boundaryColorCode.replace('0x', '#'));
    }
  }

  ChangeAnimationScale(scale){
    this.timeScale = ANIMATION_SCALE_BASE * scale;
    this.spine.state.timeScale = this.timeScale;
  }

  Reset(lastObstructiveAnimal){
    if(lastObstructiveAnimal){
      this.position.x = lastObstructiveAnimal.position.x + INTERVAL_MIN + INTERVAL_MAX * Math.random();
    }else{
      this.position.x = SCREEN_WIDTH + INTERVAL_MIN + INTERVAL_MAX * Math.random();
    }
    this.position.y = 770;
  }

  UpdateScene(dt){
    this.position.x -= dt * SCROLL_SPEED;
  }
}
