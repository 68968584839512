import * as Stats from '@drecom/stats.js';
import {Loader, Container, Graphics, Text} from 'pixi.js';
import {Spine} from 'pixi-spine';
import * as WebFont from 'webfontloader';
import {SCREEN_WIDTH, SCREEN_HEIGHT, IN_IFRAME, APP_BODY_ID, BODY_COLOR_PORTRAIT, BODY_COLOR_LANDSCAPE, BODY_COLOR_LOADING} from 'Const';
import { sound } from '@pixi/sound';
import spineCharacter from 'assets/version/animal.json';

var stats = new Stats({maxFPS:60, maxMem:100});
if(!IN_IFRAME){
  // document.body.appendChild( stats.dom );
}

class SceneManager{
  constructor(){
    this.app = null;
    this.currentScene = null;
    this.currentBGM = null;
    this.initialized = false;
    this.bodyPointerDownListener = new Set();
    this.bodyPointerUpListener = new Set();
    this.isHidden = false;

    document.body.addEventListener('pointerdown', (event) => {
      if(event.target.id == APP_BODY_ID){
        this.bodyPointerDownListener.forEach(listener => listener());
      }
    });
    document.body.addEventListener('pointerup', (event) => {
      if(event.target.id == APP_BODY_ID){
        this.bodyPointerUpListener.forEach(listener => listener());
      }
    });
    document.body.addEventListener('pointercancel', (event) => {
      if(event.target.id == APP_BODY_ID){
        this.bodyPointerUpListener.forEach(listener => listener());
      }
    });

    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.mozHidden !== 'undefined') {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    if (hidden) {
      document.addEventListener(visibilityChange, () => {
        if(document[hidden]){
          this.isHidden = true;
          // ポーズ突入
          if(this.currentBGM && !this.currentBGM.paused){
            this.currentBGM.paused = true;
          }
        }else{
          this.isHidden = false;
          if(this.currentBGM && this.currentBGM.paused){
            // console.log("this.currentBGM.paused:" + this.currentBGM.paused);
            this.currentBGM.paused = false;
          }
        }
      });
    }
  }

  async Init(app){
    if(this.initialized) return;

    this.initialized = true;
    this.app = app;
    this.app.ticker.add((dt) => {
      this.UpdateScene(dt)
    });
    this.app.view.addEventListener('webglcontextlost', (event) => {
      window.location.reload();
    });
    this.viewComputedStyle = document.defaultView.getComputedStyle(this.app.view);
    this.sceneContainer = new Container();
    this.loadingContainer = new Container();
    this.app.stage.addChild(this.sceneContainer);
    this.app.stage.addChild(this.loadingContainer);
    const loadingScreen = new Graphics();
    loadingScreen.beginFill(0x000);
    loadingScreen.drawRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);
    loadingScreen.endFill();
    loadingScreen.interactive = true;
    this.loadingContainer.addChild(loadingScreen);
    // Spineをローディングアニメーションにも使用するので最速でロードしておく
    Loader.shared.add('spineCharacter', spineCharacter);
    await new Promise(resolve => {
      Loader.shared.load(resolve);
    });
    const spine = new Spine(Loader.shared.resources.spineCharacter.spineData);
    spine.state.setAnimation(0, 'run', true);
    this.loadingContainer.addChild(spine);
    spine.x = SCREEN_WIDTH * 0.5 - 10;
    spine.y = SCREEN_HEIGHT * 0.6;
    spine.scale.x = -0.105;
    spine.scale.y = 0.105;

    await new Promise(resolve => {
      WebFont.load({
        google: {
          families: ['Rubik Bubbles', 'Rubik Moonrocks']
        },
        active: resolve,
        inactive: resolve,
      });
    });
    const loadingText = new Text('Loading...', {
      fontFamily: 'Rubik Bubbles',
      fontSize: 30,
      fill: 'white',
    });
    this.loadingContainer.addChild(loadingText);
    loadingText.position.x = SCREEN_WIDTH * 0.5;
    loadingText.position.y = spine.y + 30;
    loadingText.anchor.set(0.5);
  }

  addBodyPointerDownListener(listener){
    this.bodyPointerDownListener.add(listener);
  }
  removeBodyPointerDownListener(listener){
    if(!this.bodyPointerDownListener.has(listener)) return;
    this.bodyPointerDownListener.delete(listener);
    
  }
  addBodyPointerUpListener(listener){
    this.bodyPointerUpListener.add(listener);
  }
  removeBodyPointerUpListener(listener){
    if(!this.bodyPointerUpListener.has(listener)) return;
    this.bodyPointerUpListener.delete(listener);
  }

  async LoadScene(nextSceneClass){
    if(!this.initialized) return;
    this.loadingContainer.visible = true;
    document.body.style.backgroundColor = BODY_COLOR_LOADING;

    if(this.currentScene){
      this.currentScene.destroy({children:true});
      this.currentScene = null;
    }
    this.bodyPointerDownListener.clear();
    this.bodyPointerUpListener.clear();
    const nextScene = new nextSceneClass();
    await this.LoadResources(nextScene);
    await nextScene.Init();
    this.currentScene = nextScene;
    this.sceneContainer.addChild(nextScene);
    if(this.currentBGM){
      this.currentBGM.stop();
      this.currentBGM = null;
    }
    this.currentBGM = sound.play(nextScene.bgm(), {loop:true});
    if(this.isHidden){
      this.currentBGM.paused = true;
    }
    if(0 < parseFloat(this.viewComputedStyle.marginBottom)){
      // 縦画面
      document.body.style.backgroundColor = BODY_COLOR_PORTRAIT;
    }else{
      // 横画面
      document.body.style.backgroundColor = BODY_COLOR_LANDSCAPE;
    }
    this.loadingContainer.visible = false;
  }

  setBGM(bgmName, loop){
    if(this.currentBGM){
      this.currentBGM.stop();
      this.currentBGM = null;
    }
    this.currentBGM = sound.play(bgmName, {loop:loop});
    if(this.isHidden){
      this.currentBGM.paused = true;
    }
  }

  async LoadResources(nextScene){
    const sceneResources = nextScene.resources();
    const loadResources = sceneResources.filter(resource => !(Loader.shared.resources[resource[0]]));
    let needLoad = false;
    loadResources.forEach(resource => {
      Loader.shared.add(resource[0], resource[1]);
      needLoad = true;
    });
    if(!needLoad) return;
    await new Promise(resolve => {
      Loader.shared.load(resolve);
    });
  }

  UpdateScene(dt){
    stats.end();
    stats.begin();
    if(!this.initialized) return;
    if(!this.currentScene) return;
    this.currentScene.UpdateScene(dt);
  }
}

export const sceneManager = new SceneManager();