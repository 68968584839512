import {Container, Graphics} from 'pixi.js';
import {Spine} from 'pixi-spine';
import { getRelativeLuminance } from 'utils/Color';

export class Animal extends Container {
  constructor(spineData, inirializedAnimalTextureParser){
    super();
    this.spine = new Spine(spineData);
    if(inirializedAnimalTextureParser){
      const backgroundLuminance = getRelativeLuminance(
        inirializedAnimalTextureParser.backgroundColor.r,
        inirializedAnimalTextureParser.backgroundColor.g,
        inirializedAnimalTextureParser.backgroundColor.b,
      );
      const bodyLuminance = getRelativeLuminance(
        inirializedAnimalTextureParser.bodyColor.r,
        inirializedAnimalTextureParser.bodyColor.g,
        inirializedAnimalTextureParser.bodyColor.b,
      );
      const legColorCode = Math.abs(backgroundLuminance - bodyLuminance) <= 0.2 ? inirializedAnimalTextureParser.boundaryColorCode : inirializedAnimalTextureParser.bodyColorCode;

      this.spine.hackTextureBySlotName('body', inirializedAnimalTextureParser.texture);
      this.spine.skeleton.findSlot('leg_front').color.setFromString(legColorCode.replace('0x', '#'));
      this.spine.skeleton.findSlot('leg_back').color.setFromString(legColorCode.replace('0x', '#'));
    }
    this.addChild(this.spine);
    this.collider = new Graphics();
    this.collider.beginFill(0x00cef5);
    this.collider.drawRect(0, 0, this.width * 0.5, this.height * 0.8);
    this.collider.endFill();
    this.collider.alpha = 0.5;
    this.collider.position.x = -this.collider.width * 0.5;
    this.collider.position.y = -this.collider.height * 1.2;
    this.addChild(this.collider);
    this.collider.renderable = false;
  }

  StartAnimation(name, loop){
    if(this.spine.state.hasAnimation(name)){
      this.spine.state.setAnimation(0, name, loop);
    }
  }
}
