import {BASE_URL} from 'Const.js';

export async function postAPI(path, parmas=[]){

  let body = '';
  parmas.forEach(param => {
    if(body.length){
      body += '&';
    }
    body += encodeURIComponent(param[0]) + '=' + encodeURIComponent(param[1]);
  });
  return await fetch(`${BASE_URL}${path}`,
  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: body,
  })
  .then(response => response.json());
}

export async function getAPI(path){
  return await fetch(`${BASE_URL}${path}`,
  {
    method: 'GET',
    mode: 'cors',
  })
  .then(response => response.json());
}
