import {Application} from 'pixi.js';
import {APP_BODY_ID, APP_ROOT_ID, APP_CANVAS_ID, SCREEN_WIDTH, SCREEN_HEIGHT, BODY_COLOR_LANDSCAPE} from 'Const';
import { TopScene } from '/scenes/TopScene';
import { RunScene } from '/scenes/RunScene';
import { sceneManager } from 'SceneManager';

document.body.id = APP_BODY_ID;
document.body.style.backgroundColor = BODY_COLOR_LANDSCAPE;

const appRoot = document.createElement('div');
document.body.appendChild(appRoot);
appRoot.id = APP_ROOT_ID;

let app = new Application({width: SCREEN_WIDTH, height:SCREEN_HEIGHT, backgroundColor : 0x999999, preserveDrawingBuffer:true});
app.view.id = APP_CANVAS_ID;
app.view.style.position = 'absolute';
app.view.style.margin = 'auto';
app.view.style.marginTop = '0';
app.view.style.top = '0px';
app.view.style.left = '0px';
app.view.style.bottom = '0px';
app.view.style.right = '0px';
app.view.style.maxWidth = '100%';
app.view.style.maxHeight = '100%';

appRoot.appendChild(app.view);

async function Start(){
  await sceneManager.Init(app);
  // sceneManager.LoadScene(RunScene);
  sceneManager.LoadScene(TopScene);
}
Start();
