import {Loader} from 'pixi.js';
import { Animal } from 'objects/Animal';
import {Spine} from 'pixi-spine';
import { sound } from '@pixi/sound';

const JUMP_FORCE_BASE = 10;
const ANIMATION_SCALE_BASE = 2;

export class RunningAnimal extends Animal {
  constructor(spineData, inirializedAnimalTextureParser){
    super(spineData, inirializedAnimalTextureParser);
    this.basePositionX = 0;
    this.basePositionY = 0;
    this.isFall = false;
    this.isFinish = false;
    this.isJump = false;
    this.isJumpCancel = false;
    this.isJumpQueue = false;
    this.jumpUpForce = 0;
    this.jumpDownForce = 0;
    this.jumpDownForceRate = 0;
    this.jumpUpForceMax = 0;
    this.jumpUpForceMin = 0;
    this.ChangeAnimationScale(1);
    this.spine.stateData.setMix('run', 'jump', 0);
    this.spine.stateData.setMix('jump', 'run', 0.05);

    this.speedupEffect = new Spine(Loader.shared.resources.spineSpeedup.spineData);
    this.addChild(this.speedupEffect);
    // this.speedupEffect.position.se
    this.speedupEffect.scale.set(-3, 3);
    this.speedupEffect.position.y = -this.height;
  }

  FixBaseParams(){
    this.basePositionX = this.position.x;
    this.basePositionY = this.position.y;
    this.jumpUpForceMax = this.height * 1.5;
    this.jumpUpForceMin = this.height * 1;
  }

  ChangeAnimationScale(scale){
    this.timeScale = ANIMATION_SCALE_BASE * scale;
    if(this.isJump) return;
    this.spine.state.timeScale = this.timeScale;
  }

  SpeedUp(){
    this.speedupEffect.state.setAnimation(0, 'animation', false);
  }

  StartJump(){
    this.isJumpQueue = true;
    if(this.isJump)return;
    this.isJump = true;
    this.isJumpCancel = false;
    this.jumpUpForce = 0;
    this.jumpDownForce = 0;
    this.jumpDownForceRate = 0;
    this.spine.state.timeScale = 1; // ジャンプはアニメーション速度固定
    this.StartAnimation('jump', false);
    sound.play('se_jump');
  }

  EndJump(){
    this.isJumpCancel = true;
    this.isJumpQueue = false;
  }

  Fall(){
    this.isFall = true;
    this.spine.state.timeScale = ANIMATION_SCALE_BASE;
    this.StartAnimation('fall', false);
    this.EndJump();
    this.speedupEffect.visible = false;
  }

  Finish(){
    this.isFinish = true;
    if(this.jumpDownForceRate == 0){
      this.jumpDownForceRate = 0.1;
    }
  }

  UpdateScene(dt){
    if(this.isJump){
      if(this.isJumpCancel && this.jumpUpForceMin <= this.jumpUpForce){
        // Finish時は毎フレームの重力加速を止める
        if(!this.isFinish){
          this.jumpDownForceRate += 0.1 * dt;
        }
        this.jumpDownForce += JUMP_FORCE_BASE * this.jumpDownForceRate;
      }else{
        this.jumpUpForce += JUMP_FORCE_BASE * dt;
        if(this.jumpUpForceMax <= this.jumpUpForce){
          // 最大値の制限
          this.jumpUpForce = this.jumpUpForceMax;
          this.EndJump();
        }
      }
      this.position.y = this.basePositionY - this.jumpUpForce + this.jumpDownForce;
      if(this.isJumpCancel && this.position.y >= this.basePositionY){
        // ジャンプキャンセルルートの処理の時、ベースY以下の高さになったらジャンプを終えたものとする
        this.position.y = this.basePositionY;
        this.isJump = false;
        if(!this.isFall){
          // 転んでる場合以外は着地後に走り始める
          this.spine.state.timeScale = this.timeScale;
          this.StartAnimation('run', true);
          // 先行入力で連続ジャンプ可能
          if(this.isJumpQueue){
            this.StartJump();
          }
        }
      }
    }
  }
}
