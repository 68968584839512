import {Texture} from 'pixi.js';

export class AnimalTextureParser {
  constructor(){
    this.texture = null;
    this.backgroundColor = null;
    this.boundaryColor = null;
    this.bodyColor = null;
    this.backgroundColorCode = null;
    this.boundaryColorCode = null;
    this.bodyColorCode = null;
  }

  async Init(imageUrl){
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageUrl;
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const imageDataArray = imageData.data;
        // 左上の1ピクセルを背景色として取得
        const transparentR = imageData.data[0];
        const transparentG = imageData.data[1];
        const transparentB = imageData.data[2];
        this.backgroundColor = {r:imageData.data[0], g:imageData.data[1], b:imageData.data[2]}
        this.backgroundColorCode = this.toHex(this.backgroundColor.r, this.backgroundColor.g, this.backgroundColor.b);
        // 画像サイズ 1216 x 1216 前提で、x580 y1210 のピクセルをbodyColorとして取得
        const bodyColorIndex = 1216 * 4 * 1210 + 580 * 4;
        this.bodyColor = {r:imageData.data[bodyColorIndex], g:imageData.data[bodyColorIndex + 1], b:imageData.data[bodyColorIndex + 2]};
        this.bodyColorCode = this.toHex(this.bodyColor.r, this.bodyColor.g, this.bodyColor.b);
        for(var i = 0; i < imageDataArray.length; i += 4){
          if(imageDataArray[i] == transparentR
            && imageDataArray[i + 1] == transparentG
            && imageDataArray[i + 2] == transparentB)
          {
            imageDataArray[i + 3] = 0;
          }else if(i >= 729600 && this.boundaryColor == null){
            // 600行目以外で初めて確認した背景色以外の色を境界線の色と判断して取得
            this.boundaryColor = {r:imageDataArray[i], g:imageDataArray[i + 1], b:imageDataArray[i + 2]};
            this.boundaryColorCode = this.toHex(this.boundaryColor.r, this.boundaryColor.g, this.boundaryColor.b);
          }
        }
        this.texture = Texture.fromBuffer(imageDataArray, canvas.width, canvas.height);
        resolve();
      };
    });
  }

  toHex(r, g, b){
    return `0x${('00' + r.toString(16)).slice( -2 )}${('00' + g.toString(16)).slice( -2 )}${('00' + b.toString(16)).slice( -2 )}`;
  }
}