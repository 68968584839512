export function getRGBForCalculateLuminance(rgb) {
	if (rgb <= 0.03928){
		return rgb / 12.92;
	} else {
		return Math.pow(((rgb + 0.055) / 1.055), 2.4);
	}
}

export function getRelativeLuminance(r, g , b) {
	let R = getRGBForCalculateLuminance(getSRGB(r));
	let G = getRGBForCalculateLuminance(getSRGB(g));
	let B = getRGBForCalculateLuminance(getSRGB(b));
	return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

export function getSRGB(_8bitColor) {
	return _8bitColor / 255;
}
