import { Point, Graphics } from 'pixi.js';
import {APP_ROOT_ID, APP_CANVAS_ID, SCREEN_WIDTH, SCREEN_HEIGHT} from 'Const';

export class TextInput extends Graphics {

  constructor(options) {
    super();

    this.appCanvas = document.getElementById(APP_CANVAS_ID);
    this._rendererViewStyle = document.defaultView.getComputedStyle(this.appCanvas);
    this._element = null;

    options = options || {};
    const text = options.text || '';
    const width = options.width;
    const height = options.height;
    const x = options.x;
    const y = options.y;

    this._element = TextInput._createElement(options);
    this._eventListeners = {
      input: this._onInput.bind(this),
      focus: this._onFocus.bind(this),
      blur: this._onBlur.bind(this),
    };

    this._element.addEventListener('input', this._eventListeners.input);
    this._element.addEventListener('focus', this._eventListeners.focus);
    this._element.addEventListener('blur', this._eventListeners.blur);
    document.getElementById(APP_ROOT_ID).appendChild(this._element);

    this._width  = width;
    this._height = height;
    this.value = text;

    this.setPosition(x, y);
    this.resizeEventBind = this.resizeEvent.bind(this);
    this.on('added', this.resizeEventBind);
    window.addEventListener('resize', this.resizeEventBind);
  }

  resizeEvent(_){
    this.setPosition(this.x, this.y);
    const domSize = this._toDomSize(this.width, this.height);
    this._setElementSize(domSize.x, domSize.y);
  }

  get value() {
    return this._value;
  }

  set value(value) {
    if (this._element) {
      this._element.value = value;
    }

    this._value = value;
  }

  setPosition(x, y) {
    this.position.set(x, y);
    let globalPosition = this.position;
    if (this.parent !== null && this.parent !== undefined) {
      globalPosition = this.parent.toGlobal(this.position);
    }

    const domPosition = this._toDomPosition(globalPosition.x, globalPosition.y);
    this._setElementPosition(domPosition.x, domPosition.y);
  }

  setDomPosition(x, y) {
    const glPosition = this._toGlPosition(x, y);
    this.position.set(glPosition.x, glPosition.y);
    if (this.parent !== null && this.parent !== undefined) {
      this.position.copy(this.parent.toLocal(this.position));
    }

    this._setElementPosition(x, y);
  }

  get width() {
    return this._width;
  }

  set width(value) {
    const size = this._toDomSize(value, this.height);
    this._setElementSize(size.x, size.y);
    this._width = value;
  }

  get height() {
    return this._height;
  }

  set height(value) {
    const size = this._toDomSize(this.width, value);
    this._setElementSize(size.x, size.y);
    this._height = value;
  }

  destroy(options) {
    if (this._element) {
      this._element.removeEventListener('input', this._eventListeners.input);
      this._element.removeEventListener('focus', this._eventListeners.focus);
      this._element.removeEventListener('blur', this._eventListeners.blur);
      this._element.parentNode.removeChild(this._element);
      this._element = null;
    }
    window.removeEventListener('resize', this.resizeEventBind);

    // exec blur processing if in focus
    if (this._isFocus) {
      this._onBlur();
    }

    super.destroy(options);
  }

  _onInput() {
    if (this._element) {
      this._value = this._element.value;
    }
    this.emit('input', this);
  }

  _onFocus() {
    this._isFocus = true;
    this.emit('onfocus', this);
  }

  _onBlur() {
    this._isFocus = false;
    this.emit('onblur', this);
  }

  static _createElement(options) {
    const type = options.type || 'text';

    const element = document.createElement('input');

    element.style.position = 'absolute';
    element.style.width = '0px';
    element.style.height = '0px';
    element.style.top = '0px';
    element.style.left = '0px';
    element.style.border = '0px';
    element.style.opacity = '1.0';
    element.style.padding = '0px';
    element.style.borderRadius = '0';
    element.type = type;
    element.autofocus = true;
    element.style.zIndex = '10000';
    if(options.textAlign){
      element.style.textAlign = options.textAlign;
    }
    if(options.placeholder){
      element.placeholder = options.placeholder;
    }    

    // set options
    if (options.fontFamily !== undefined) element.style.fontFamily = options.fontFamily;
    // if (options.fontSize !== undefined) element.style.fontSize = options.fontSize;
    if (options.fontStyle !== undefined) element.style.fontStyle = options.fontStyle;
    if (options.fontWeight !== undefined) element.style.fontWeight = options.fontWeight;

    return element;
  }

  _setElementSize(x, y) {
    // MEMO: margin 0 premise.
    const windowHeight =(parseFloat(this._rendererViewStyle.height) + parseFloat(this._rendererViewStyle.marginTop) + parseFloat(this._rendererViewStyle.marginBottom));
    const widthRatio = x / (parseFloat(this._rendererViewStyle.width) + parseFloat(this._rendererViewStyle.marginLeft) +parseFloat(this._rendererViewStyle.marginRight));
    const heightRatio = y / windowHeight;

    const element = this._element;

    element.style.width = `${widthRatio * 100}%`;
    element.style.height = `${heightRatio * 100}%`;
    element.style.fontSize = `${y}px`;

    const screenPixelRatio = parseFloat(this._rendererViewStyle.height) / SCREEN_HEIGHT;
  }

  _setElementPosition(x, y) {
    // MEMO: margin 0 premise.
    const leftRatio = x / (parseFloat(this._rendererViewStyle.width) + parseFloat(this._rendererViewStyle.marginLeft) +parseFloat(this._rendererViewStyle.marginRight));
    const topRatio = y / (parseFloat(this._rendererViewStyle.height) + parseFloat(this._rendererViewStyle.marginTop) + parseFloat(this._rendererViewStyle.marginBottom));

    const element = this._element;

    element.style.left = `${leftRatio * 100}%`;
    element.style.top = `${topRatio * 100}%`;
  }

  _toDomSize(x, y) {
    return new Point(
      parseFloat(this._rendererViewStyle.width) * x / SCREEN_WIDTH,
      parseFloat(this._rendererViewStyle.height) * y / SCREEN_HEIGHT,
    );
  }

  _toDomPosition(x, y) {
    const size = this._toDomSize(x, y);
    return new Point(
      size.x + parseFloat(this._rendererViewStyle.marginLeft),
      size.y + parseFloat(this._rendererViewStyle.marginTop)
    );
  }

  _toGlSize(x, y) {
    return new Point(
      SCREEN_WIDTH * x / parseFloat(this._rendererViewStyle.width),
      SCREEN_HEIGHT * y / parseFloat(this._rendererViewStyle.height)
    );
  }

  _toGlPosition(x, y) {
    const size = this._toGlSize(
      x - parseFloat(this._rendererViewStyle.marginLeft),
      y - parseFloat(this._rendererViewStyle.marginTop)
    );

    return size;
  }
}